@import "@/assets/styles/variables.scss";













































































































































#team {

  #members-box {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow: hidden;
    position: relative;
    margin-bottom: 70px;
  }

  #members {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    transition: 300ms ease-in-out;
  }

  #member-spacer {
    height: 500px;
  }

  .member {
    display: flex;
    align-items: flex-start;

    .member-name {
      font-size: 55px;
      font-weight: 400;
      margin-top: 100px;
    }

    .member-description {
      font-size: 16px;

      b {
        font-weight: 600;
      }
    }

    .member-content {
      display: none;
      padding: 0 60px;
      max-width: 640px;
    }

    .member-image {
      min-width: 20vw;
      max-width: 20vw;
      filter: grayscale(100);
      cursor: pointer;
    }

    &.active {
      max-width: none;

      .member-name {
        margin-top: 3vh;
        line-height: 1.0;
        margin-bottom: 20px;
      }

      .member-content {
        display: block;
      }

    }

  }
}
